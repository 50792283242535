.logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2.5rem 0;

  .logo-section__logo {
    margin-bottom: 1.5rem;
    img {
      object-fit: contain;
    }
  }

  .logo-section__title {
    color: #a6894d;
    font-weight: 500;
    font-size: 1.25rem;
    font-family: "EB Garamond", serif;
    margin-bottom: 1.5rem;
  }
  .logo-section__description {
    color: #a6894d;
    font-weight: 500;
    font-size: 1rem;
    font-family: "EB Garamond", serif;
    max-width: 600px;
    text-align: center;
    margin-inline: 1rem;
    > p:first-child {
      margin-bottom: 1.5rem;
    }
  }
}
