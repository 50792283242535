.rank-inner {
  display: flex;
  align-items: center;

  .rank-inner__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: 1rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
