.view-card {
  background-color: #262626;
  min-height: inherit;
  font-family: "EB Garamond", serif;
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 230px;

  .view-card__emoji {
    margin-bottom: 0.5rem;
  }

  .view-card__title {
    color: #a6894d;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
  .view-card__week-all {
    display: flex;
    width: 100%;
    max-width: 230px;
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
  .view-card__time-1,
  .view-card__time-2,
  .view-card__all {
    color: #545454;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-weight: 500;
  }

  .view-card__time-2 {
    font-size: 0.625rem;
  }

  .view-card__points {
    color: #a6894d;
    font-size: 3rem;
    font-weight: 500;
  }

  .view-card__all_points {
    color: #a6894d;
    font-weight: 500;
  }
}
