@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&family=Jost:wght@300;400;500;600;700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  font-size: 16px;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
#root {
  min-height: inherit;
}

.nice-scroll-style {
  &::-webkit-scrollbar {
    width: 10px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #a6894d;
    background: #a6894d;
    position: relative;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1c1c1c;
    border-radius: 0;
    background: #1c1c1c;
  }
}
