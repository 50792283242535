.main-page {
  background-color: #111111;
  min-height: inherit;

  .main-page__container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
