.current-rank {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #393939;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    &.rank-1 {
      background-color: #a6894d;
    }
    &.rank-2 {
      background-color: #808080;
    }
    &.rank-3 {
      background-color: #bf7400;
    }
  }